/* src/components/Footer.css */
.footer {
    background-color: black; 
    color: white; 
    text-align: center;
    padding: 20px 0;
    width: 100%;
    margin-top: 40px; 
  }
  
  .footer__content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .footer__content p {
    margin: 5px 0;
  }
  