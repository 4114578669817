/* src/components/Services.css */

@keyframes zoomIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.services__title, .services__subtitle, .service {
  opacity: 0;
  transition: opacity 0.5s ease-out; 
}

.animate-zoom {
  animation: zoomIn 1s ease-out forwards; 
}

.animate-slide {
  animation: slideUp 1s ease-out forwards; 
}


.services {
  text-align: center;
  padding: 100px 20px; 
  background-color: black; 
}

.services__title {
  font-size: 36px;
  color: #6495ED;
  margin-bottom: 10px;
}

.services__subtitle {
  font-size: 18px;
  color: #bbb; 
  margin-bottom: 40px;
}

.services__container {
  display: flex;
  justify-content: center; 
  flex-wrap: wrap;
  gap: 20px; 
}

.service {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, background-color 0.3s, color 0.3s;
  width: 350px;
  height: 450px; 
  text-align: left;
  position: relative;
}

.service:hover {
  transform: scale(1.05);
  background-color: #6495ED;
  color: white; 
}

.service__image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  transition: transform 0.3s;
}

.service:hover .service__image {
  transform: scale(1.1);
}

.service__content {
  padding: 20px;
}

.service__title {
  font-size: 24px;
  margin: 0;
  color: black; 
}

.service__description {
  font-size: 16px;
  margin: 10px 0 0;
  color: black;
}

.service:hover .service__title,
.service:hover .service__description {
  color: white; 
}

@media (max-width: 768px) {
  .services__container {
    flex-direction: column;
    align-items: center;
  }

  .service {
    width: 100%;
    max-width: 600px;
  }
}