/* src/components/Home.css */
.home {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: black;
  text-align: center;
  padding: 100px 20px 0 20px;
  transition: background-color 0.3s, color 0.3s;
}

.home__content {
  max-width: 800px;
  position: relative;
}

.home__subtitle,
.home__title,
.home__description {
  opacity: 0;
  animation: fadeInSlideLeft 1s ease-in-out forwards;
}
.home__truck-image {
  width: 600px; 
  animation: fadeIn 1s ease-in-out forwards; 
  margin-top:-180px;
}

@media screen and (max-width: 450px) {
  .home__truck-image {
    width: 350px; 
  }
}
.home__content {
  max-width: 800px;
}

.home__subtitle {
  font-size: 18px;
  color: #6495ED;
  margin-bottom: 10px;
  animation-delay: 0.2s;
  margin-top: 20px;
}

.home__title {
  font-size: 48px;
  color: white;
  margin-bottom: 20px;
  animation-delay: 0.4s;
}

.home__description {
  font-size: 18px;
  color: #bbb;
  margin-bottom: 15px;
  animation-delay: 0.6s;
}

.home__buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.home__button {
  opacity: 0;
  padding: 15px 30px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  animation: fadeInSlideUp 1s ease-in-out forwards;
  animation-delay: 1.2s;
}

.home__button--primary {
  background-color: #6495ED;
  color: white;
}
.home__button--primary:hover{
  background-color: #4169E1;
}

.home__button--secondary {
  background-color: transparent;
  color: #6495ED;;
  border: 2px solid #6495ED;
}

@keyframes fadeInSlideLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInSlideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}