.privacy-policy {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.privacy-policy__content {
    background-color: #fff; /* Changed to white for better readability */
    color: #333; /* Text color for contrast */
    padding: 20px;
    border-radius: 5px;
    max-width: 800px;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Added shadow for depth */
}

.privacy-policy__content h2 {
    margin-top: 0;
    font-size: 24px;
}

.privacy-policy__content p {
    line-height: 1.6;
    margin-bottom: 10px;
}

.privacy-policy__content button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.privacy-policy__content button:hover {
    background-color: #0056b3;
}