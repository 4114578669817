/* src/index.css */
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: black; 
  color: white; 
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
